<template src="./AcademicAllCoursesUniversity.html">

</template>

<script>
import coursesService from "@/services/Courses";
import { mapGetters } from "vuex";
import likeCommentService from "@/services/LikeAndComment";
import certificateTemplateService from "@/services/CertificateTemplate";

export default {
  name: "AcademicAllCoursesUniversity",
  data() {
    return {
      listCourses: [],
      selectedItemIndex: 0,
      courseData: null,
      comments: [],
      ratingScore: 0,
      courseCertificateData: null
    };
  },
  methods: {
    async getAllCoursesRecommendations(type = '@Medellin') {
      try {
        const coursesRecommendations = await coursesService.getAllCoursesRecommendations(type)
        console.log('coursesRecommendations', coursesRecommendations);
      } catch (e) {
        console.log('e', e);
      }
    },
    async getCourse(courseId) {
      try {
        this.courseData = await coursesService.getCourseById(courseId);
        await this.getCertificateByCourse(courseId);
        await this.getScore();
        await this.getComments();
      } catch (e) {
        console.log('e', e);
      }
    },
    async getCertificateByCourse(courseId) {
      try {
        this.courseCertificateData = await certificateTemplateService.getCertificateTemplateByCourse(courseId);
      } catch (e) {
        this.courseCertificateData = null;
        console.log('e', e);
      }
    },
    async getAllCourse() {
      try {
        this.listCourses = await coursesService.getAllCoursesByUniversity(this.user.institutionId.id)
        await this.getCourse(this.listCourses[0].idCourse);
        await this.getCertificateByCourse(this.listCourses[0].idCourse);
      } catch (e) {
        console.log('e', e);
      }
    },
    async getScore() {
      try {
        const data = {
          typePost: "course",
          post: this.courseData.curso.id,
        };
        const response =
          await likeCommentService.starsAndCommentsByPost(
            data
          );
        if (response.length > 0) this.ratingScore = response;
      } catch (e) {
        console.log(e)
      }
    },
    async getComments() {
      try {
        this.comments = await likeCommentService.getCourseComments(this.courseData.curso.id, "course");
      } catch (e) {
        console.log('error', e);
      }
    },
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
  },
  mounted() {
    this.getAllCoursesRecommendations();
    this.getAllCourse();
  },

};
</script>

<style scoped lang="scss" src="./AcademicAllCoursesUniversity.scss">

</style>
