import { render, staticRenderFns } from "./AcademicAllCoursesUniversity.html?vue&type=template&id=157254a6&scoped=true&"
import script from "./AcademicAllCoursesUniversity.vue?vue&type=script&lang=js&"
export * from "./AcademicAllCoursesUniversity.vue?vue&type=script&lang=js&"
import style0 from "./AcademicAllCoursesUniversity.scss?vue&type=style&index=0&id=157254a6&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "157254a6",
  null
  
)

export default component.exports