import { nodeInstance } from "@/plugins/axios";

const likeCommentService = {}

likeCommentService.getAnnouncementComments = (params) => {
    return nodeInstance.get(`/api/comment/getByPost?post=${params.postId}&typePost=${params.typePost}`);
};

likeCommentService.getCourseComments = (post, typePost) => {
    return nodeInstance.get(`/api/comment/getByPost`, { params:{post, typePost} });
};

likeCommentService.createComment = obj => {
    return nodeInstance.post(`/api/comment`, obj);
};

likeCommentService.getComments = (id) => {
    return nodeInstance.get(`/api/likeComment/${id}`);
}

likeCommentService.commentAndRate = obj => {
    return nodeInstance.post(`/api/stars`, obj);
};

likeCommentService.qualification = (params) => {
    return nodeInstance.get(`/api/stars/average?typePost=${params.typePost}&post=${params.post}`);
};

likeCommentService.starsAndCommentsByPost = (params) => {
    return nodeInstance.get(`/api/stars/byPost?typePost=${params.typePost}&post=${params.post}`);
};

likeCommentService.likeByPost = (params) => {
    return nodeInstance.get(`/api/like/getByPost?typePost=${params.typePost}&postId=${params.postId}`);
};

likeCommentService.createLike = (obj) => {
    return nodeInstance.post(`/api/like`, obj);
};

likeCommentService.deleteLike = id => {
    return nodeInstance.delete(`/api/like/${id}`);
};

export default likeCommentService;
